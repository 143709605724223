import { HOME_API_ENDPOINTS, REQUEST_METHODS } from '@/constants/network'
import baseFetch from '@/network/baseFetch'
import { BbdJson } from '@/types/home'
interface CompliancePolicyStatus {
    isDisplayed: boolean
}
export const getCompliancePolicyStatus = async (): Promise<CompliancePolicyStatus> => {
    try {
        const header = {
            caller: 'UI'
        }
        const response = await baseFetch<CompliancePolicyStatus>({
            url: '/compliance',
            method: REQUEST_METHODS.GET,
            headers: header
        })
        if (response) {
            return response
        }
        return Promise.reject()
    } catch (e) {
        console.error(e)
        return Promise.reject()
    }
}

export const setCompliancePolicyStatus = async (payload: { action: string }) => {
    try {
        const header = {
            caller: 'UI'
        }
        const response = await baseFetch<ResponseType>({
            url: '/compliance',
            method: REQUEST_METHODS.POST,
            headers: header,
            body: JSON.stringify(payload)
        })
        return response
    } catch (e) {
        console.error(e)
    }
}
export const getBBDJsonData = async (): Promise<BbdJson[]> => {
    try {
        
        const response = await baseFetch<BbdJson[]>({
            url: HOME_API_ENDPOINTS.BBDJSONURL ,
            method: REQUEST_METHODS.GET
          
        })
        if (response) {
            return response
        }
        return []
    } catch (e) {
        console.error(e)
        return []
    }
}