import React from 'react';

const Hotel = () => {
    return (
        <svg width="20" height="20">
            <g fill="none" fill-rule="evenodd">
                <path fill="#FFF" d="M0 0h20v20H0z"/>
                <path d="M17.65 16.364v-2.432H2.354v2.425H.002L0 4.73c0-1.458 2.358-1.458 2.358 0v6.791h15.256L20 11.515v4.849h-2.35zm-8.895-5.096h-4.96c-.942 0-.853-2.173.03-2.173h3.941V7.478c0-.764.471-1.195.989-1.281v-.012h.104c.056-.004.113-.004.17 0h9.67c.959 0 1.301.31 1.301 1.299v3.789H8.755v-.005zm-3.13-3.177c-1.036 0-1.875-.855-1.875-1.909s.84-1.91 1.875-1.91c1.035 0 1.875.856 1.875 1.91 0 1.054-.84 1.909-1.875 1.909z" fill="#999"/>
            </g>
        </svg>
    )
}
export default Hotel;