
import { dataLayerGA } from '@/analytics/GA/dataLayerHelper';
import Flight from '@/assets/svg/Flight';
import Hotel from '@/assets/svg/Hotel';
import { ACTION_NAMES } from '@/constants/analytics';
import { Container } from '@cleartrip/ct-design-components';
import React from 'react'
import Clevertap from '@/analytics/clevertap'

const FlightHotelLogoHeader = () => {
    const handleIconClick = (url:string, actionName:string) => {
        dataLayerGA({ clicked_option_name: actionName, section: 'Navigation' }, ACTION_NAMES.headerMenuClick)
        Clevertap.event('Air_UI_Action', {
            action_type: 'Icon',
            action_name: actionName,
            ui_index: null
        })
        window.location.href = url
    }

    return (
        <>
            <Container className="flex flex-middle mr-6" cursor="pointer" onClick={()=>{handleIconClick('/flights', 'global_nav_flights')}}>
                <Flight/>
            </Container>
           
             <Container className="flex flex-middle mr-6" cursor="pointer" onClick={()=>{handleIconClick('/hotels', 'global_nav_hotels')}}>
                <Hotel/>
            </Container>
        </>
    )
}

export default FlightHotelLogoHeader;