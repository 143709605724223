import { useState } from 'react'
import { Button, Container, Typography } from '@cleartrip/ct-design-components'
import { HomeWallet } from '@/assets/icons/HomeWallet'
import { formatCurrency } from '@/utils'
import { AccountLogo } from '@/assets/icons/AccountLogo'
import useFetch from '@/network/useFetch'
import { HOME_API_ENDPOINTS } from '@/constants/network/home'
import { useAppDispatch, useAppSelector } from '@/redux/store/hooks'
import { getApiDomain } from '@/utils/general/url'
import { getAbData } from '@/redux/slices/abConfig'
import Clevertap from '@/analytics/clevertap'

import { Stencil } from '../common/Stencil'
import { IAccountDetailsResponse, IWalletResponse } from './type'
import LoggedInDropdown from './LoggedInMenu'
import { ACTION_NAMES, flight_UI_ACTION } from '@/constants/analytics'
import { ACCOUNTS_WALLET } from '@/constants/paths'
import { ActionMapper, NavigateType } from '@/types'
import { getActionPayload } from '@/utils/general/actions'
import { isEmpty } from '@/utils/general/browserHelper'
import { getTestIdProps } from '@/utils/general/components'
import { PAGE_NAME } from '@/constants/pages/common'
import { HOME_PAGE_COMPONENTS_NAME } from '@/constants/components'
import SupportHeader from '@/components/home/Support'

const accountInfoURL = HOME_API_ENDPOINTS.ACCOUNT_DETAILS

const SignedInInfo = ({ isSrp }: { isSrp: boolean }) => {
    const { response: walletData, isLoading: walletLoading } = useFetch<IWalletResponse>(
        HOME_API_ENDPOINTS.HOME_WALLET_INFO
    )
    const { response: accountDetailsResponse } = useFetch<IAccountDetailsResponse>(accountInfoURL, {
        headers: {
            caller: getApiDomain()
        }
    })
    const { wallet } = walletData || {}
    const walletBalance = !isEmpty(wallet) && wallet?.[0]?.balance
    const [showDropdown, setShowDropdown] = useState(false)
    const abData = useAppSelector(getAbData)
    const dispatcher = useAppDispatch()

    const openDropdown = () => {
        setTimeout(() => {
            setShowDropdown(true)
        }, 10)
    }

    const handleWalletClick = () => {
        Clevertap.event(flight_UI_ACTION, {
            action_type: 'BUTTON',
            action_name: ACTION_NAMES.hpWalletClick,
            walletBalance: walletBalance,
            wallet_icon_displayed: abData && abData?.wallet_display && abData?.wallet_display === 'b' ? 'yes' : 'no'
        })
        dispatcher(
            getActionPayload('NAVIGATE', {
                type: NavigateType.IN_PAGE_REDIRECT,
                url: ACCOUNTS_WALLET
            })
        )
    }

    return (
        <>
            {walletLoading ? (
                <Stencil width={75} height={15} shimmer />
            ) : walletBalance ? (
                <Container className="flex mr-4 c-pointer">
                    <Container onClick={handleWalletClick} className="flex">
                        <Container className="flex flex-middle">
                            <HomeWallet />
                        </Container>
                        <Container className="pl-2">
                            <Typography variant="P3">{formatCurrency(walletBalance)}</Typography>
                        </Container>
                    </Container>
                </Container>
            ) : null}

            {isSrp && <SupportHeader isSignedIn={true}/>}

            <Container
                onClick={openDropdown}
                className="p-2 flex flex-middle flex-between br-2 hoverGrey c-pointer"
                {...getTestIdProps({
                    pageName: PAGE_NAME.HOME,
                    componentName: HOME_PAGE_COMPONENTS_NAME.MY_ACCOUNT
                })}
            >
                <AccountLogo />
                <Container className="ml-2">
                    <Typography variant="B2" className="c-pointer">
                        My account
                    </Typography>
                </Container>
            </Container>
            {showDropdown && (
                <LoggedInDropdown setShowDropdown={setShowDropdown} accountInfo={accountDetailsResponse} />
            )}
        </>
    )
}

const HomeUserProfile: React.FC<{ isSignedIn: boolean, isSrp:boolean }> = ({ isSignedIn, isSrp }) => {
    const globalDispatcher = useAppDispatch()

    const handleLogin = () => {
        //Dispatch ation to open login modal
        globalDispatcher(getActionPayload('OPEN_MODAL', { type: ActionMapper.OPEN_LOG_IN_MODAL }))
    }

    const renderHeaderRight = () => {
        if (isSignedIn) {
            return <SignedInInfo isSrp={isSrp}/>
        } else {
            return (
                <Button
                    size={'medium'}
                    onClick={handleLogin}
                    color="tertiary"
                    style={{ padding: '6px 4px 8px 4px' }}
                    minWidth={'128px'}
                    {...getTestIdProps({
                        pageName: PAGE_NAME.HOME,
                        componentName: HOME_PAGE_COMPONENTS_NAME.LOGIN_BUTTON
                    })}
                >
                    Log in / Sign up
                </Button>
            )
        }
    }

    return <Container className="flex flex-middle">{renderHeaderRight()}</Container>
}

export default HomeUserProfile
