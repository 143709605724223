import { dataLayerGA } from '@/analytics/GA/dataLayerHelper';
import Support from '@/assets/svg/Support';
import { ACTION_NAMES } from '@/constants/analytics';
import { Container, Typography } from '@cleartrip/ct-design-components';
import React from 'react';

const handleSupportClick = (isSignedIn:boolean) => {
    dataLayerGA({ clicked_option_name: 'Support', section: 'Navigation' }, ACTION_NAMES.headerMenuClick)
    let url = '/support'
    if (isSignedIn) {
        url = '/account/trips'
    }
    window.open(url, '_blank')
}


const SupportHeader: React.FC<{ isSignedIn: boolean }> = ({isSignedIn}) => {
    return (
        <span
            className={'td-none c-neutral-400 hoverGrey flex flex-middle c-pointer p-2 br-4 mr-4'}
            onClick={() => handleSupportClick(isSignedIn)}
        >
            <Support />
            <Container className="ml-2">
                <Typography variant="B2" className="c-pointer">
                    Support
                </Typography>
            </Container>
        </span>
    )
}

export default SupportHeader;