import React from 'react';

const Flight = () => {
    return (
        <svg width="18" height="20.5">
            <path d="M16.115.426l-4.387 4.427L1.676 2.172 0 3.862l8.285 4.403-3.35 3.38-2.606-.322-1.325 1.336 2.955 1.568 1.554 2.981 1.325-1.336-.304-2.613 3.35-3.38 4.27 8.303 1.676-1.69-2.544-9.936 4.479-4.527c1.203-1.214-.462-2.802-1.65-1.603z" fill="#36C" fill-rule="evenodd"/>
        </svg>
    )
}
 export default Flight
