import { useState, useEffect } from 'react'
import Image from 'next/image'
import { Container } from '@cleartrip/ct-design-components'
import { CleartripLogo } from '@/assets'
import HomeUserProfile from './HomeUserProfile'
import { useAppDispatch } from '@/redux/store/hooks'
import { NavigateType } from '@/types'
import { getActionPayload } from '@/utils/general/actions'
import { getApiDomain } from '@/utils/general/url'
import { FASTUI_DOMAIN } from '@/constants/cloudinary'
import ComplianceBanner from './ComplianceBanner'
import { getCompliancePolicyStatus } from '@/api/homepage'
import FlightHotelLogoHeader from '@/components/home/FlightHotelLogoHeader'

const getBBDtimestamps = () => {
    const startDate = new Date('2023-10-07T00:00:00').getTime()
    const endDate = new Date('2023-10-15T23:59:59').getTime()

    const currentTime = new Date().getTime()
    const timeDifference = startDate - currentTime

    return { startDate, endDate, currentTime, timeDifference }
}

const hasBBD23started = () => {
    const { timeDifference, currentTime, endDate } = getBBDtimestamps()

    return timeDifference <= 0 && currentTime < endDate
}

const HomeHeader: React.FC<{ isSignedIn: boolean, isSrp:boolean }> = ({ isSignedIn, isSrp }) => {
    const [showBBDlive, setShowBBDlive] = useState(false)
    const [isBBDlive, setIsBBDlive] = useState(() => hasBBD23started())
    const [showComplianceBanner, setShowComplianceBanner] = useState(false)

    async function getComplianceStatus() {
        try {
            const data = await getCompliancePolicyStatus()
            setShowComplianceBanner(!data?.isDisplayed)
        } catch (e) {
            console.log(e)
        }
    }

    const dispatcher = useAppDispatch()
    const routeToHome = () => {
        const url = getApiDomain()
        dispatcher(
            getActionPayload('NAVIGATE', {
                type: NavigateType.IN_PAGE_REDIRECT,
                url
            })
        )
    }

    useEffect(() => {
        const { timeDifference, currentTime, endDate } = getBBDtimestamps()
        getComplianceStatus()
        // If the target time is in the future, set a timeout to show the GIF
        // maximum future time is set to 1 hour, as its unlikely user will stay for a longer time
        if (timeDifference > 0 && timeDifference < 3600000) {
            const timeoutId = setTimeout(() => {
                setIsBBDlive(true)
                setTimeout(() => {
                    setShowBBDlive(true)
                }, 4000)
            }, timeDifference)

            return () => {
                clearTimeout(timeoutId)
            }
        } else if (timeDifference <= 0 && currentTime < endDate) {
            setIsBBDlive(true)
            const timeoutId = setTimeout(() => {
                setShowBBDlive(true)
            }, 4000)
            return () => {
                clearTimeout(timeoutId)
            }
        }
    }, [])

    return (
        <>
            <Container className="p-relative w-100p">
                {showComplianceBanner && <ComplianceBanner setShowBanner={setShowComplianceBanner} />}
                <Container className={'px-2'} css={{ maxWidth: '1264px', margin: 'auto' }}>
                    <Container className="row p-relative" marginLeft={'-8px'} marginRight={'-8px'}>
                        <Container className="col pl-2 pr-4">
                            <Container className="flex flex-middle flex-between pt-4 pb-2">
                                <Container className='flex flex-middle'>
                                    <Container className="flex flex-middle" cursor="pointer" onClick={routeToHome}>
                                        {isBBDlive ? (
                                            <Image
                                                src={
                                                    showBBDlive
                                                        ? `${FASTUI_DOMAIN}/image/upload/f_auto,q_auto,dpr_2/resources/images/CTlogoBBDlive.gif`
                                                        : `${FASTUI_DOMAIN}/image/upload/f_auto,q_auto,dpr_2/resources/images/CTlogoBBDreveal.gif`
                                                }
                                                alt="Cleartrip"
                                                height={'50'}
                                                width={'150'}
                                            />
                                        ) : (
                                            <Container className='mr-6'>
                                                <CleartripLogo />
                                            </Container>
                                        )}
                                    </Container>
                                    {isSrp && <FlightHotelLogoHeader/>}
                                </Container>
                                <HomeUserProfile isSrp={isSrp} isSignedIn={isSignedIn} />
                            </Container>
                        </Container>
                    </Container>
                </Container>
            </Container>
        </>
    )
}

export default HomeHeader
